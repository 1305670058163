<template>

  <!--  <div id="preloader" class="preloader" >
      <div class='inner'>
        <div class='line1'></div>
        <div class='line2'></div>
        <div class='line3'></div>
      </div>
    </div>-->
  <section class="fxt-template-animation fxt-template-layout1 align-items-start loaded">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-12 fxt-bg-color">
          <div class="fxt-content">
            <div class="fxt-header">
              <a href="javascript:;" class="fxt-logo w-25">
                <img :src="generalSettings.logo_url" v-if="generalSettings.logo" alt="Logo" class="">
              </a>
              <div class="fxt-page-switcher">
                <router-link to="sign-in" class="switcher-text1">{{ l('admin:genel:login_now') }}</router-link>
                <router-link to="sign-up" class="switcher-text1">{{ l('admin:genel:register') }}</router-link>  
              </div>
            </div>
            <div class="fxt-form">
              <h2>{{ l('user:languages:sifre_yenileme') }}</h2>
              <p>{{ l('web:forgot:Lütfen sistemde kayıtlı telefon numaranızı giriniz') }}</p>
              <form
                  class="form w-100"
                  autocomplete="off"
                  @submit="onSubmitForgotPassword($event, form)"
              >

              <div class="form-group z-index-2">
                  <div class="fxt-transformY-50 fxt-transition-delay-4 ">
                    <vue-tel-input
                        v-if="parameters"
                        class="form-control form-control-lg form-control-solid shadow-none"
                        v-model="form.phone"
                        :preferredCountries="['TR']"
                        :autoDefaultCountry="!!parameters.tum_ulkelere_gsm_izni"
                        :default-country="defaultCountry"
                        :onlyCountries="!parameters.tum_ulkelere_gsm_izni?['TR']:[]"
                        @input="onInput"
                        v-bind="bindProps"
                        style="outline: 0"
                        v-maska="'(###) ###-##-##'"
                    />
                  </div>
                    <!--end::Input-->
                </div>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-3">
                    <div class="fxt-content-between">
                      <button ref="submitButton"
                              id="kt_sign_in_submit"
                              type="submit" class="fxt-btn-fill">
                          <span class="indicator-label"> {{ l('admin:genel:gonder') }} </span>

                        <span class="indicator-progress"> {{l('admin:transfer:lutfen_bekleyiniz')}}
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div class="col-md-6 col-12 fxt-none-767 fxt-bg-img"
             style="background-image: url(media/img/solife-img5.png)"></div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
} from 'vue';
import {Actions} from '@/store/enums/StoreEnums';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {VueTelInput} from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import toast from '@/core/functions/toast';
import {l} from '@/core/helpers/lang';
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import post from "@/core/functions/post";
import { api_url, get_country_api_url } from '@/ayar';
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: 'sign-in',
  components: {
    VueTelInput
  },
  data() {
    return {
      phone: '',
      password: '',
      bindProps: {
        placeholder: l('admin:languages:langugaes434'),
        required: false,
        autocomplete: 'off',
        name: 'phone',
        autoFormat: true,
        customValidate: true,
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false,
          styleClasses: 'shadow-none'

        }
      },
    };
  },
  methods: {
    onInput(phone, phoneObject) {
      if (phoneObject) {
        if (phoneObject.formatted) {
          this.form.phone = phoneObject.country.dialCode + phoneObject.nationalNumber;
        }
      }
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();


    const submitButton = ref<HTMLElement | null>(null);

    const smsCode = ref('');

    const form = reactive({
      phone: ''
    });
    const parameters = computed(() => store.getters.getParameterList);

    const onSubmitForgotPassword = (event, values) => {
      event.preventDefault();
      store.dispatch(Actions.PURGE_AUTH);

      if (event.submitter) {
        event.submitter.setAttribute('data-kt-indicator', 'on');
      }
      setTimeout(() => {
        store
            .dispatch(Actions.FORGOT_PASSWORD, values)
            .then(() => {
                Swal.fire({
                title              : form.phone + l('user:languages:languages269'),
                input              : 'text',
                showCancelButton   : false,
                confirmButtonText  : l ( 'admin:transfer:Doğrula' ),
                showLoaderOnConfirm: true,
                preConfirm         : ( code ) => {
                   if ( code ) {
                     post(api_url+'forgotCheck', {
                       gsm : form.phone,
                       code : code,
                     }).then(res => {
                       if(res.data.durum){
                          smsCode.value = code;
                          toast ( l( 'admin:transfer:Telefon Numarası Doğrulandı' ) , true );
                          Swal.fire({
                              title            : l ('admin:languages:languages110'),
                              html             :
                                      '<input class="swal2-input" id="pass" type="password" name="password" placeholder="'+l( 'admin:genel:yeni_sifre')+'">' +
                                      '<input class="swal2-input" id="pass2" type="password" name="passwordT" placeholder="'+l('admin:genel:yeni_sifre_tekrar')+'">',
                              focusConfirm     : false,
                              confirmButtonText: l('admin:transfer:guncelle'),
                              preConfirm       : () =>
                              {
                                let pass  = document.getElementById('pass').value;
                                let passT = document.getElementById('pass2').value;

                                post(api_url+'changePassword', {
                                    phone : form.phone,
                                    password : pass,
                                    password_confirmation: passT,
                                    code  : smsCode.value
                                }).then(() => {

                                      Swal.fire({
                                        text: l( 'web:transfer:Parolanız Başarıyla Değiştirildi' ),
                                        icon: "success",
                                        buttonsStyling: false,
                                        confirmButtonText: l('admin:transfer:Devam Et'),
                                        customClass: {
                                          confirmButton: "btn fw-bold btn-light-primary",
                                        },
                                      }).then(function () {
                                        // Go to page after successfully login
                                        router.push({ name: 'sign-in' });
                                      });
                                  
                                }).catch(({ response }) => {
                                   toast ( response.data.data, false );
                                })
                                
                              }
                          })
                       }else {
                          toast ( l ( 'admin:transfer:Telefon Numarası Doğrulanamadı' ), false );
                       }
                     })
                   }
                }
                });
            })
            .catch(() => {
               toast(store.getters.getErrors, false);
            });
        event.submitter.removeAttribute('data-kt-indicator', 'off');
      }, 2000);
    };

    const defaultCountry = ref('tr');

    ApiService.get(get_country_api_url).then(function (payload) {
      defaultCountry.value = payload.data.country_code
    })

    return {
      onSubmitForgotPassword,
      submitButton,
      form,
      defaultCountry,
      parameters,
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
      l
    };
  },
});
</script>
<style>
.vti__input {
  background: transparent !important;
}

.vue-tel-input:focus-within {
  border: none !important;
}
</style>